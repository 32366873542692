@import "../../convertPxToVw.scss";

.HeaderContainer {
  // height: vw(158px);
  background-color: transparent;
  position: fixed;
  display: flex;
  padding: vw(20px) vw(50px);
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  column-gap: vw(130px);
  justify-content: space-between;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.HeaderContainerScrolled {
  background-color: #000000;
}

.HeaderLinkAnchor {
  text-decoration: none;
}

.HeaderLink {
  color: #fff;
  font-family: "Poppins";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.selected {
  color: #1cd490;
}

.HeaderLinks {
  display: flex;
  column-gap: vw(32px);
  flex-grow: 2;
  justify-content: center;
}

.LogoContaier {
  flex-grow: 1;
}

.Logo {
  height: vw(32px);
}

.btn {
  width: vw(100px);
  // height: vw(45px);
  position: relative;
  z-index: 1;
  cursor: pointer;
  border: 2px solid #1cd490;
  border-radius: 12px;
  // border-image-source: url(../../assets/Border.svg);
  // border-image-slice: 30;
  // border-image-width: 30px;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 400;
  padding: 5px;
}

.btnContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  text-decoration: none;
}

.Hamburger {
  display: none;
}

@media screen and (max-width: 600px) {
  .Hamburger {
    display: block;
  }

  .btnContainer {
    display: none;
  }

  .btn,
  .HeaderLinks {
    display: none;
  }

  .HeaderContainer {
    justify-content: space-between;
  }

  .Logo {
    width: vwm(130px);
    height: vwm(25px);
    margin-top: vwm(11px);
  }

  .HamburgerOptions {
    position: absolute;
    top: vwm(50px);
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    row-gap: vwm(10px);
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: 1;

    .HeaderLink {
      font-size: vwm(23px);
    }
  }
}
