@import "../../convertPxToVw.scss";

.CompanyDetails {
  background-color: black;
}
.CountersBackground {
  width: vw(1200px);
  height: vw(160px);
  margin: auto;
}

.CounterText {
  display: flex;
  position: relative;
  column-gap: vw(106px);
  top: vw(-135px);
  left: vw(300px);
  width: fit-content;
  align-items: center;
}
.CountersHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 700;
  line-height: vw(55px);
  width: vw(299px);
}
.Counters {
  display: flex;
  flex-direction: column;
}
.CounterNumber {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.CounterHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.StepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StepsContainerHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.StepsContainerBorder {
  width: vw(400px);
  background: linear-gradient(to left, #000000, #5cc8a0, #5cc8a0, #000000);
  height: 2px;
  border: none;
  margin-bottom: vw(50px);
}

.StepsContainerInfo {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  width: vw(809px);
  margin-bottom: vw(80px);
}

.StepsContainerImage {
  width: vw(1046px);
  height: vw(440px);
}

.CTA {
  margin-top: vw(164px);
  position: relative;
  margin-bottom: vw(150px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CTAImage {
  height: vw(340px);
  position: absolute;
  width: 100%;
}

.CTAInfo {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px vw(120px);
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.CTADecor {
  width: vw(313px);
  height: vw(313px);
}
.CTAAction {
  display: flex;
  flex-direction: column;
}
.CTAActionHeader {
  color: #fff;
  font-family: "Poppins";
  font-size: vw(34px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: vw(366px);
}

.CTAActionButton {
  border: 3px;
  // border-image-source: url(../../assets/Border.svg);
  // border-image-slice: 30;
  // border-image-width: 30px;
  // border-image-outset: 6px;
  border-radius: 12px;
  border: 2px solid #1cd490;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  margin-top: vw(20px);
  width: vw(200px);
  padding: vw(11px) vw(45px);
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.AppImagesContainer {
  display: flex;
  align-items: center;
  column-gap: vw(35px);
  justify-content: center;
}

.AppImagesTextInfo {
  display: flex;
  flex-direction: column;
  row-gap: vw(13px);
  align-items: center;
}

.AppImagesHeader {
  background-image: linear-gradient(to right, #1cd490 40%, #194e48 100%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;

  text-align: center;
  font-family: "Poppins";
  font-size: vw(40px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.AppImagesSubHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: vw(532px);
}
.AppImagesInfo {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: vw(569px);
  margin-bottom: vw(27px);
}

.AppImages {
  height: 26vw;
  width: 45.5vw;
}

.AppImagesApp {
  height: 4vw;
  width: 15vw;
}

.AppImagesAppContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: vw(25px);
  column-gap: vw(16px);
}

.AppImagesUpperBorder {
  margin-bottom: vw(69px);
  margin-left: 0;
  width: vw(1200px);
  background: linear-gradient(to left, #000000 20%, #5cc8a0 100%, #000000);
  height: 2px;
  border: none;
}

.AppImagesLowerBorder {
  margin-bottom: vw(150px);
  margin-top: vw(105px);
  width: vw(1200px);
  margin-right: 0;
  background: linear-gradient(to right, #000000 20%, #5cc8a0 100%, #000000);
  height: 2px;
  border: none;
}

.CeoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vw;
}

.CeoContainerUpperBorder {
  margin-bottom: vw(20px);
  margin-left: 0;
  width: vw(500px);
  background: linear-gradient(to left, #000000, #5cc8a0, #000000);
  height: 2px;
  border: none;
}
.CeoContainerLowerBorder {
  margin-bottom: vw(99px);
  margin-top: 2vw;
  width: vw(500px);
  margin-right: 0;
  background: linear-gradient(to right, #000000, #5cc8a0, #000000);
  height: 2px;
  border: none;
}

.CeoContainerInfo {
  display: flex;
  width: vw(1300px);
  align-items: center;
  column-gap: vw(62px);
}

.CeoContainerText {
  width: vw(760px);
}

.CeoQuotes {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: vw(606px);
  margin-left: vw(90px);
}

.CeoPlatforms {
  margin-left: 40vw;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: vw(30px);
}

.CeoSocials {
  height: 2.5vw;
}

.CeoActionButton {
  border: 3px;
  border-image-source: url(../../assets/Border.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  border-image-outset: 6px;
  border-radius: 12px;

  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(21px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: vw(99px);
  width: vw(255px);
  height: vw(72px);
  padding: vw(20px) vw(35px);
  box-sizing: border-box;
  margin-left: vw(67px);
  outline: none;
  background: black;
  cursor: pointer;
}

.CeoImage {
  width: vw(478px);
  height: vw(521px);
}

.StepsMobileImage {
  display: none;
}

@media screen and (max-width: 600px) {
  .CountersBackground {
    width: vwm(390px);
    height: vwm(68px);
  }

  .CounterText {
    top: vwm(-50px);
    left: 10.615385vw;
  }

  .CountersHeader {
    font-size: vwm(12px);
  }

  .CounterHeader {
    font-size: vwm(8px);
  }
  .CounterNumber {
    font-size: vwm(12px);
    font-weight: 700;
  }

  .StepsContainerHeader {
    font-size: vwm(35px);
  }

  .StepsContainerBorder {
    width: vwm(200px);
    margin-top: vwm(8px);
    margin-bottom: vwm(40px);
  }

  .StepsContainerInfo {
    font-size: vwm(15px);
    width: vwm(358px);
  }

  .StepsContainerImage {
    display: none;
  }

  .StepsMobileImage {
    display: inline;
    // height: vwm(430px);
    width: 100vw;
  }

  .CTAInfo {
    padding-left: vwm(20px);
    padding-top: vwm(15px);
    padding-right: 0;
  }

  .CTA {
    height: vwm(200px);
  }

  .CTAImage {
    height: vwm(200px);
    width: 100vw;
  }

  .CTADecor {
    height: vwm(180px);
    width: vwm(180px);
  }

  .CTAActionHeader {
    font-size: vwm(20px);
    width: vwm(181px);
    margin-bottom: vwm(8px);
  }

  .CTAActionButton {
    width: 34.076923vw;
    /* height: 7.8461538462vw; */
    font-size: 3.6923076923vw;
    padding: 2.0769230769vw;
  }

  .AppImagesAppContainer {
    margin-top: 2vw;
  }

  .AppImagesContainer {
    flex-direction: column-reverse;

    .AppImages {
      width: vwm(279px);
      height: vwm(173px);
    }

    .AppImagesTextInfo {
      margin-top: vwm(25px);
      width: 100%;

      .AppImagesHeader {
        font-size: vwm(22px);
        font-weight: 700;
      }

      .AppImagesSubHeader {
        font-size: vwm(18px);
        width: vwm(355px);
      }

      .AppImagesInfo {
        width: vwm(355px);
        font-size: vwm(15px);
      }

      .AppImagesApp {
        width: vwm(120px);
        height: vwm(40px);
      }
    }
  }

  .CeoContainerInfo {
    flex-direction: column-reverse;

    .CeoImage {
      width: vwm(250px);
      height: vwm(312px);
    }

    .CeoContainerText {
      width: 100%;

      .CeoContainerUpperBorder,
      .CeoContainerLowerBorder {
        width: vwm(200px);
      }

      .CeoQuotes {
        font-size: vwm(15px);
        width: vwm(298px);
      }

      .CeoPlatforms {
        justify-content: flex-end;
        margin-top: vwm(20px);

        img {
          width: vwm(24px);
          height: vwm(24px);
        }
      }
    }
  }

  .CeoActionButton {
    width: vwm(180px);
    height: vwm(40px);
    margin: auto;
    font-size: vwm(16px);
    margin-top: vwm(32px);
  }
}
