@import "../../convertPxToVw.scss";

.ContactUsContainer {
  background-color: black;
  padding-top: vw(160px);
}

.ContactUs {
  height: 85vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactUsImage {
  position: absolute;
  width: 90vw;
  height: 100%;
}

.ContactUsHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: vw(17px);
  position: absolute;
  top: 0;
}

.ContactUsForm {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 60vh;
  align-items: center;
  gap: 6vw;
}

.ContactUsFormChild1 {
  display: flex;
  gap: 2vw;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ContactUsAddress {
  padding: 1vw;
  border-radius: vw(16px);
  border: 2px solid #6af7c3;
  opacity: 0.4;
  background: linear-gradient(180deg, #000 99.99%, #1e7e6e 100%);
  color: #fff;
  max-width: 20vw;
  position: relative;
}

.AddressHeader {
  font-family: "Poppins";
  font-size: 1.3vw;
  font-weight: 600;
}

.AddressSubHeader {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: vw(15px);
  padding: vw(10px) 0 0 0;
  font-weight: 400;
  display: flex;
  gap: 1vw;
  align-items: center;
}

.AddressIcon {
  height: 1vw;
}

.AddressRibbon {
  position: absolute;
  top: 0;
  right: 12px;
  height: 3vw;
}

.ContactUsFormSubHeader {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: vw(16px);
  width: vw(376px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: vw(10px);
  position: relative;
}

.Form {
  display: flex;
  flex-direction: column;
  row-gap: vw(10px);
}

.Captcha {
  display: flex;
  justify-content: center;
}

.SubmitForm {
  display: flex;
  width: vw(431px);
  height: vw(60px);
  //   box-sizing: border-box;
  padding: vw(20px) vw(28px);
  justify-content: center;
  align-items: center;
  border-radius: vw(32px);
  border: 2px solid #1cd490;
  background: transparent;
  cursor: pointer;
  position: relative;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.SubmitForm:disabled {
  background: linear-gradient(180deg, #000 99.99%, #1e7e6e 100%);
}

.InputField {
  width: vw(431px);
  height: vw(60px);
  border-radius: 16px;
  border: 2px solid #6af7c3;
  padding-left: vw(20px);
  opacity: 0.4;
  box-sizing: border-box;
  background: linear-gradient(180deg, #000 99.99%, #1e7e6e 100%);
  color: #fff;
}

.InputAreaField {
  width: vw(431px);
  height: vw(140px);
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid #6af7c3;
  padding-left: vw(20px);
  padding-top: vw(15px);
  opacity: 0.4;
  background: linear-gradient(180deg, #000 99.99%, #1e7e6e 100%);
  color: #fff;
}

.InputFieldError,
.InputAreaFieldError {
  border: 3px solid #ff0000 !important;
}

.Success {
  color: rgb(0, 217, 255);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.Error {
  color: #ff0000;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.Footer {
  height: 27vw;
  position: relative;
  padding-top: vw(86px);
  margin-top: vw(103px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.FooterContents {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
}

.FooterContentsContactLogo {
  margin-bottom: vw(64.3px);
  height: vw(46px);
  width: vw(262px);
}

.FooterContentsContactAddressDetails {
  display: flex;
  align-items: flex-start;
  column-gap: vw(10px);
  margin-bottom: 8px;
}
.FooterContentsContactAddressLogo {
  margin-top: 5px;
  height: vw(26px);
  width: vw(26px);
}
.FooterContentsContactAddress {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: vw(340px);
}

.FooterContentsLinksHeader {
  color: #fff;
  font-family: "Poppins";
  font-size: vw(30px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: vw(25px);
}

.FooterContentsLinksContainer {
  display: flex;
  flex-direction: column;
  row-gap: vw(25px);
}

.FooterContentsLink {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.FooterContentsNewsletter {
  width: vw(544px);
}

.FooterContentsNewsletterSubHeader {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: vw(486px);
}

.FooterContentsNewsletterForm {
  display: flex;
  justify-content: center;
  column-gap: vw(20px);
  margin-top: vw(50px);
  align-items: center;
}

.FooterContentsNewsletterSubmitForm {
  border: 3px;
  border-image-source: url(../../assets/SubscribeBorder.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  border-image-outset: 6px;
  border-radius: 12px;
  width: vw(198px);
  height: vw(45px);
  padding: 0 vw(36px);
  box-sizing: border-box;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: vw(5px);
  background: transparent;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(20px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  cursor: pointer;
}

.FooterContentsNewsletterSubmitForm:disabled {
  background: black;
}

.FooterContentsNewsletterInput {
  width: vw(326px);
  height: vw(50px);
  padding-left: vw(15px);
  border-radius: 12px;
  background: rgba(114, 108, 134, 0.2);
  border: none;
  color: white;
}

.FooterContentsNewsletterInput::placeholder {
  color: #ffffffb0;
}

.FooterImage {
  position: absolute;
  height: vw(384px);
  width: 100%;
  bottom: 0;
  z-index: 0;
}

.CeoImage {
  width: vw(478px);
  height: vw(521px);
}

.ContactUsMobile {
  display: none;
}

.FooterDetails {
  text-align: start;
  margin-top: 1vw;
}

@media screen and (max-width: 600px) {
  .ContactUs {
    height: vwm(547px);
    .ContactUsImage {
      display: none;
    }

    .ContactUsMobile {
      position: absolute;
      display: block;
      width: vwm(330px);
      height: vwm(547px);
    }

    .ContactUsHeader {
      position: absolute;
      margin-top: vwm(24px);
      font-size: vwm(24px);
    }

    .ContactUsForm {
      width: vwm(280px);
      margin-left: 0;
      position: relative;

      .ContactUsFormChild1 {
        display: none;
      }

      .ContactUsFormSubHeader {
        font-size: vwm(15px);
        margin-bottom: vwm(26px);
        width: 100%;
      }

      .InputField {
        width: vwm(280px);
        margin-bottom: vwm(10px);
        height: vwm(48px);
      }

      .InputAreaField {
        width: vwm(280px);
        height: vwm(120px);
      }

      .SubmitForm {
        width: vwm(280px);
        height: vwm(48px);
        font-size: vwm(16px);
        border-radius: vwm(32px);
        margin-top: vwm(10px);
      }
    }
  }

  .Footer {
    height: vwm(800px);
    background-position: bottom;
    background-size: contain;
  }

  .FooterContents {
    flex-direction: column;
    padding: 0 vwm(17px);
    width: 100vw;
    width: fit-content;

    .FooterContentsContactAddressDetails {
      column-gap: vwm(16px);
    }

    .FooterContentsLinksHeader {
      font-size: vwm(20px);
      margin-top: vwm(29px);
    }

    .FooterContentsLink {
      font-size: vwm(14px);
    }

    .FooterContentsLinksContainer {
      row-gap: vwm(10px);
    }

    .FooterContentsContactLogo {
      width: vwm(156px);
      height: vwm(24px);
    }

    .FooterContentsContactAddressLogo {
      width: vwm(24px);
      height: vwm(24px);
    }

    .FooterContentsContactAddress {
      font-size: vwm(15px);
      width: vwm(288px);
    }

    .FooterContentsNewsletter {
      width: fit-content;
      margin-top: vwm(34px);

      .FooterContentsLinksHeader {
        text-align: center;
        font-size: vwm(17px);
      }

      .FooterContentsNewsletterSubHeader {
        font-size: vwm(15px);
        text-align: center;
        width: 100%;
      }

      .FooterContentsNewsletterInput {
        width: vwm(212px);
        height: vwm(48px);
      }

      .FooterContentsNewsletterForm {
        // justify-content: flex-start;
      }

      .FooterContentsNewsletterSubmitForm {
        width: vwm(128px);
        height: vwm(48px);
        font-size: vwm(16px);
        margin-left: vwm(7px);
      }
    }
  }

  .Success, .Error {
    font-size: 16px;
  }
}
