.Hero {
  background-color: #154137;
  color: white;
  padding: 40px 20px;
  border-radius: 0px 0px 100% 100%;
}

.HeroText {
  margin-top: 5.5vw;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // z-index: 1000;
  display: flex;
  flex-direction: column;
  row-gap: vw(32px);
  align-items: center;
}

.HeroTextInfo {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 1vw 0;
}

@media screen and (max-width: 600px) {
  .HeroText {
    margin-top: 9.5vw;
    font-size: 7vw;
  }
}