.Main {
  color: rgba(255, 255, 255, 0.7);
  margin: 3vw 10vw;
}

.Title {
  margin: 1.5vw 0 0 0;
  color: #318e78;
  font-family: "Poppins";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SubTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0.8vw 0 1vw 0;
}

.SubTitle2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0.5vw;
}

@media screen and (max-width: 600px) {
  .Title {
    font-size: 6vw;
  }

  .SubTitle {
    margin: 1vw 0 1vw 0;
  }

  .SubTitle2 {
    margin: 0.9vw;
  }
}
