@import "../../convertPxToVw.scss";

.HeroContent {
  position: relative;
  margin-top: 2vw;
}

.HeroText {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // z-index: 1000;
  display: flex;
  flex-direction: column;
  row-gap: vw(32px);
  align-items: center;
}

.HeroTextInfo {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Poppins";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: vw(716px);
  margin: 0 0 1vw 0;
}

.GetStarted {
  display: flex;
  width: 11vw;
  height: 3.3vw;
  justify-content: center;
  align-items: center;
  border-radius: vw(12px);
  background: #14a371;
  cursor: pointer;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(20px);
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  text-decoration: none;
  color: #fff;
}

.HeroTextButtonGroup {
  display: flex;
  column-gap: 2vw;
  justify-content: center;
}

.CompanyLogosCarouselComponent {
  // width: vw(800px);

  img {
    margin-right: vw(80px);
  }
}

.CompanyLogosCarousel {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: vw(80px);
  padding: vw(50px) 0;
  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-image: linear-gradient(to bottom, #000000, #5cc8a0, #5cc8a0, #000000);
  border-image-slice: 1;
  position: relative;
  z-index: 0;
}

.CompanyLogos {
  margin-top: vw(171px);
  height: vw(90px);
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: vw(80px);

  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-image: linear-gradient(to bottom, #000000, #5cc8a0, #5cc8a0, #000000);
  border-image-slice: 1;
  display: none;
}

.ClientLogos {
  width: vw(99px);
  height: vw(30px);
  filter: grayscale(100%);
}

.HeroContainerVideo {
  position: absolute;
  // z-index: -1;
  width: 100%;
  opacity: 0.4;
  filter: grayscale(30%);
}

@media screen and (max-width: 600px) {
  .LeftThunder,
  .RightThunder {
    width: vwm(200px);
    height: vwm(300px);
  }

  .HeroText {
    font-size: 8.076923vw;
  }

  .HeroTextInfo {
    font-size: vwm(14px);
    width: vwm(354px);
    color: white;
    height: vwm(105px);
  }

  .PlayButton {
    width: vwm(43px);
    height: vwm(43px);
  }

  .GetStarted {
    width: 28vw;
    height: vwm(43px);
    font-size: vwm(15px);
    opacity: 0.8;
    border-radius: 2.5vw;
  }

  .HeroTextButtonGroup {
    width: vwm(223px);
    height: vwm(43px);
  }

  .HeroContainerTop {
    height: 100vw;
  }

  .CompanyLogosCarousel {
    display: none;
  }

  .CompanyLogos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: vwm(390px);
    height: fit-content;
    margin: auto;
    border: none;
    column-gap: unset;
  }

  .ClientLogos {
    border: 1px solid rgba(125, 125, 125, 0.5);
    width: vwm(76px);
    height: vwm(22px);
    padding: vwm(33px) vwm(12px) vwm(29px) vwm(12px);
    border-top: 0;
    border-left: 0;
  }
  .ClientLogos:nth-child(n + 7) {
    border-bottom: 0;
  }

  .ClientLogos:nth-child(3n) {
    border-right: 0;
  }

  .CompanyLogosParent {
    background-color: #000000;
    overflow: hidden;
    padding-top: vwm(85px);
  }

  .HeroContainerVideo {
    width: 168%;
    left: -38vw;
  }
}

.VideoContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0; /* Layer it above the video */
}

.BgVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: grayscale(30%);
}

.CompanyLogosParent {
  background-color: #000000;
  overflow: hidden;
}
