@import "../../convertPxToVw.scss";

.ContainerHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.ContainerHeaderBorder {
  width: vw(400px);
  background: linear-gradient(to left, #000000, #5cc8a0, #5cc8a0, #000000);
  height: 2px;
  border: none;
  margin-bottom: vw(70px);
}

.CarouselParent {
  background: linear-gradient(to bottom, #1b373a, #1c4e4b, #1e7769);
  padding: 2.9375vw 5.375vw;
}

.CarouselContainer {
  max-width: 80%;
  margin: auto;

  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  .slick-prev::before,
  .slick-next::before {
    color: #000;
  }
}

.Card {
  border: 3px;
  border-image-source: url(../../assets/FeatureBorder.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  background: rgba(0, 0, 0, 0.4);
  height: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #fff;
  text-decoration: none;
}

.CardImage {
  width: 100%;
  background-color: white;
  // height: 250px;
  flex-grow: 1;
  object-fit: contain;
  // border-radius: 12px;
}

.CardText {
  margin: auto;
  margin-top: vw(15px);
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: "Poppins";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
}


@media screen and (max-width: 600px) {
  .ContainerHeader {
    font-size: vwm(35px);
    // padding-top: vwm(85px);
  }

  .ContainerHeaderBorder {
    width: vwm(200px);
  }

  .CarouselParent {
    padding: 15vw 0;
  }

  .CardText {
    font-size: vwm(15px);
    width: unset;
  }
}