@import "../../convertPxToVw.scss";

.Container {
  background-color: black;
  padding-top: vw(112px);
  padding-left: vw(270px);
}

.CalculatorContainer {
  width: vw(900px);
  height: vw(836px);
  padding-bottom: vw(76px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background-image: url("../../assets/CalculatorBackground.png");
  background-repeat: no-repeat;
}

.CalculatorInfo {
  width: vw(790px);
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CalculatorHeader {
  color: #fff;
  font-family: "Poppins";
  font-size: vw(40px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: vw(30px);
  margin-bottom: vw(20px);
}

.CalculatorWidgetHeader {
  margin-top: vw(20px);
  margin-left: vw(20px);

  display: flex;
  column-gap: vw(8px);
}

.CalculatorDropdownLabel {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins";
  font-size: vw(12px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: vw(10px);
}

.CalculatorWidget {
  margin-top: vw(48px);
  border: 3px;
  border-image-source: url(../../assets/Border.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  width: vw(790px);
  height: vw(439px);
}

.DropdownArrow {
  width: fit-content;
  height: vw(20px);
  border-left: 1px solid #1cd490;
}

.Dropdown {
  width: vw(130px);
  height: vw(44px);
}
.DropdownCurrency {
  width: vw(100px);
  height: vw(44px);
}

.DropdownControl {
  border-image-source: url(../../assets/DropdownBorder.png);
  border-image-slice: 30;
  border-image-width: 50px;
  border-image-outset: 5px;

  display: flex;
  padding: vw(12px) 0px vw(12px) vw(12px);
  justify-content: space-between;
  align-items: center;
  background: transparent;
  box-sizing: border-box;

  color: #fff;
  font-family: "Poppins";
  font-size: vw(14px);
  font-style: normal;
  font-weight: 400;
}
.DropdownControlInput {
  width: vw(120px);
  height: vw(52px);
  border-image-source: url(../../assets/DropdownBorder.png);
  border-image-slice: 30;
  border-image-width: 50px;
  border-image-outset: 5px;

  display: flex;
  padding: vw(12px) 0px vw(12px) vw(12px);
  justify-content: space-around;
  background: transparent;
  box-sizing: border-box;

  color: #fff;
  font-family: "Poppins";
  font-size: vw(14px);
  font-style: normal;
  font-weight: 400;
  outline: none;
}

.SubmitButton {
  width: vw(120px);
  height: vw(42px);
  background-image: url(../../assets/CalSubmitBackground.svg);
  padding: vw(14px) vw(33px);
  background-repeat: no-repeat;
  box-sizing: border-box;

  align-self: self-end;
  margin-left: vw(15px);

  color: #fff;
  font-family: "Poppins";
  font-size: vw(12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.CalculatorChart {
  margin-left: vw(35px);
  margin-top: vw(40px);
  background-image: url(../../assets/ChartBackground.svg);
  background-repeat: no-repeat;
  padding-left: vw(24px);
}

.ChartDisplayContainer {
  display: flex;
  column-gap: vw(20px);
}

.ChartDisplayData {
  width: vw(180px);
  height: vw(28px);
  border-image-source: url(../../assets/DropdownBorder.png);
  border-image-slice: 30;
  border-image-width: 50px;
  border-image-outset: 5px;
  margin-top: vw(20px);
  margin-bottom: vw(25px);

  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins";
  font-size: vw(12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: vw(7px);
}

.ChartDisplayDataNumber {
  font-size: vw(14px);
  font-weight: 600;
  color: white;
}

.ModalHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(22px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: vw(75px);
}

.CalculatorOverlay {
  border: 3px;
  border-image-source: url(../../assets/Border.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  width: vw(558px);
  height: vw(420px);
  position: absolute;
  top: vw(302px);
  left: vw(441px);
  backdrop-filter: blur(4px);
}

.InputField {
  width: vw(431px);
  height: vw(60px);
  border-radius: 16px;
  border: 2px solid #6af7c3;
  padding-left: vw(20px);
  opacity: 0.4;
  box-sizing: border-box;
  background: linear-gradient(180deg, #000 99.99%, #1e7e6e 100%);
  margin-top: vw(34px);
  margin-left: vw(64px);
}

.SubmitForm {
  display: flex;
  width: vw(431px);
  height: vw(52px);
  //   box-sizing: border-box;
  padding: vw(20px) vw(28px);
  justify-content: center;
  align-items: center;
  border-radius: vw(32px);
  border: 2px solid #1cd490;
  background: transparent;
  cursor: pointer;
  margin: auto;
  margin-top: vw(21px);

  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.ModalClass {
  background: transparent;
}

@media screen and (max-width: 600px) {
  .ChartContainer {
    display: none;
  }

  .Container {
    padding-left: vwm(32px);
  }

  .CalculatorHeader {
    font-size: vwm(24px);
    font-weight: 600;
  }

  .CalculatorWidget {
    width: vwm(250px);
    height: vwm(500px);
  }

  .CalculatorInfo {
    font-family: "Poppins";
    font-size: vwm(15px);
    width: vwm(272px);
    font-weight: 400;
  }

  .CalculatorDropdownLabel {
    font-size: vwm(15px);
    margin-bottom: vwm(10px);
  }

  .CalculatorWidgetHeader {
    flex-direction: column;
    margin-left: vwm(20px);
    margin-top: vwm(10px);
  }

  .CalculatorChart {
    width: 58vw;
    height: 26vw;
    background-size: 59vw 24vw;
    background-repeat: no-repeat;
  }

  .Dropdown,
  .DropdownCurrency {
    width: vwm(150px);
    height: fit-content;
  }

  .DropdownControlInput {
    width: vwm(150px);
    font-size: vwm(15px);
    height: fit-content;
  }

  .SubmitButton {
    width: vwm(150px);
    height: vwm(48px);
    align-self: unset;
    font-size: vwm(15px);
    padding: vwm(10px) vwm(30px);
  }

  .DropdownControl {
    font-size: vwm(15px);
  }

  .DropdownArrow {
    width: vwm(20px);
    height: vwm(20px);
  }

  .CalculatorContainer {
    width: vwm(330px);
    height: vwm(1500px);
    background-size: vwm(330px) vwm(1047px);
  }

  .ChartDisplayContainer {
    flex-direction: column;
    padding-top: vwm(6px);

    .ChartDisplayData {
      width: vwm(180px);
      height: vwm(30px);
      line-height: vwm(30px);
      font-size: vwm(12px);
    }

    .ChartDisplayDataNumber {
      font-size: vwm(12px);
    }
  }

  .CalculatorOverlay {
    width: vwm(250px);
    height: vwm(200px);
    top: 200vw;
    left: 16vw;
    outline: none;
  }

  .ModalHeader {
    font-size: vwm(15px);
  }
  .InputField {
    width: vwm(200px);
    height: vwm(48px);
    margin-left: vwm(25px);
  }
  .SubmitForm {
    width: vwm(200px);
    height: vwm(48px);
    font-size: vwm(15px);
  }
}
