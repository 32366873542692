@import "../../convertPxToVw.scss";

.FeatureContainer {
  background-color: black;
  padding-bottom: 2.5vw;
  margin-top: 2.5vw;
}
.FeatureContainerHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: vw(35px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.FeatureContainerHeaderBorder {
  width: vw(400px);
  background: linear-gradient(to left, #000000, #5cc8a0, #5cc8a0, #000000);
  height: 2px;
  border: none;
  margin-bottom: vw(70px);
}

.FeatureListContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: vw(30px);
  column-gap: vw(35px);
  justify-content: space-evenly;
  background: linear-gradient(to bottom, #1b373a, #1c4e4b, #1e7769);
  padding: vw(47px) vw(150px);
}

.FeatureCard {
  width: 20vw;
  height: 23vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  border: 3px;
  border-image-source: url(../../assets/FeatureBorder.svg);
  border-image-slice: 30;
  border-image-width: 30px;
  // border-image-outset: 1px;
  // border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px;
}

.FeatureCardImage {
  width: 9.8vw;
  height: 9.8vw;
}

.FeatureCardHeader {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: vw(25px);
}

.FeatureCardSubText {
  margin: auto;
  margin-top: vw(15px);
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: "Poppins";
  font-size: vw(20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.FeatureCardLink {
  align-self: end;
  height: 2vw;
}

.AdditionalFeatures {
  margin-top: vw(138px);
  display: flex;
  align-items: center;
}

.AdditionalFeaturesDesc {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.AdditionalFeatures2 {
  margin-top: vw(100px);
  display: flex;
  align-items: center;
  column-gap: vw(145px);
  justify-content: center;
}

.HeroText {
  font-family: "Poppins";
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.HeroTextHeader {
  background-image: linear-gradient(to right, #1cd490 20%, #194e48 60%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
}

.HeroTextInfo {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Poppins";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: vw(20px);
  width: vw(569px);
}

.AdditionalCustomerFeatures {
  display: flex;
  justify-content: space-around;
  margin-top: vw(90px);
}

.AdditionalCustomerFeature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.AdditionalCustomerFeature:nth-child(2) {
  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-image: linear-gradient(to bottom, #000000, #5cc8a0, #5cc8a0, #000000);
  border-image-slice: 1;
}

.AdditionalCustomerFeatureTextHeader {
  color: #fff;
  font-family: "Poppins";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 0.5vw;
}
.AdditionalCustomerFeatureTextInfo {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: "Poppins";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: vw(486px);
}

.AdditionalCustomerFeatureImage {
  width: 10.375vw;
  height: 10.375vw;
  margin-bottom: vw(25px);
}

.Applications {
  display: flex;
  margin-top: vw(80px);
  margin-bottom: vw(160px);
  justify-content: center;
}
.Application {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1vw;
  width: 30%;
}
.ApplicationImage1 {
  width: 9.5vw;
  height: 12.75vw;
}
.ApplicationImage2 {
  width: 9.5vw;
  height: 12.75vw;
}

.ApplicationImage3 {
  width: 9.5vw;
  height: 12.75vw;
}

.ApplicationText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vw;
}

.ApplicationTextHeader {
  color: #fff;
  font-family: "Poppins";
  font-size: vw(20px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.ApplicationTextInfo {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: "Poppins";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 80%;
}

.Application:nth-child(2) {
  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-image: linear-gradient(to bottom, #000000, #5cc8a0, #5cc8a0, #000000);
  border-image-slice: 1;
}

.Ecosystem {
  width: 25.5vw;
  height: 21.375vw;
}

.GridBalancing {
  width: 33.625vw;
  height: 22.125vw;
}

@media screen and (max-width: 600px) {
  .FeatureContainer{
    padding: 36px;
    margin-top: 0;
  }

  .FeatureContainerHeader {
    font-size: vwm(35px);
    // padding-top: vwm(85px);
  }

  .FeatureContainerHeaderBorder {
    width: vwm(200px);
  }

  .FeatureListContainer {
    background: black;
    flex-direction: column;
    align-items: flex-start;
    row-gap: vwm(40px);
    padding: 0;
  }

  .FeatureCard {
    border: none;
    height: vwm(218px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0;
  }

  .FeatureCardImage {
    height: vwm(120px);
    width: vwm(120px);
    margin-left: 0;
  }

  .FeatureCardHeader {
    margin-top: vwm(11px);
    font-size: vwm(22px);
    text-align: left;
  }

  .FeatureCardSubText {
    margin: 0;
    font-size: vwm(15px);
    width: unset;
    text-align: left;
  }

  .AdditionalFeatures {
    flex-direction: column;
    margin-top: vwm(70px);
    align-items: flex-start;

    .HeroText {
      font-size: vwm(22px);
      width: 100%;
    }
    .HeroTextInfo {
      font-size: vwm(15px);
      width: vwm(356px);
    }
  }

  .GridBalancing {
    display: none;
  }

  .AdditionalCustomerFeatures {
    flex-direction: column;
    border: none;
    row-gap: vwm(20px);
    margin-top: vwm(29px);
  }

  .AdditionalCustomerFeatureImage {
    width: vwm(80px);
    height: vwm(80px);
    margin-left: vwm(13px);
    border: 2px solid;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    padding-right: vwm(10px);
    border-image: linear-gradient(
      to bottom,
      #000000,
      #5cc8a0,
      #5cc8a0,
      #000000
    );
    border-image-slice: 1;
  }

  .AdditionalCustomerFeature {
    padding-bottom: vwm(9px);
    flex-direction: row;
    border: 2px solid;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-image: linear-gradient(to left, #000000, #5cc8a0, #5cc8a0, #000000);
    border-image-slice: 1;
  }

  .AdditionalFeaturesText {
    display: flex;
    flex-direction: column;
    width: vwm(255px);
    margin-left: vwm(15px);
  }

  .AdditionalCustomerFeatureTextHeader {
    font-size: vwm(18px);
    text-align: unset;
  }

  .AdditionalCustomerFeatureTextInfo {
    font-size: vwm(15px);
    width: vwm(255px);
    text-align: left;
  }

  .AdditionalCustomerFeature:nth-child(2) {
    border: none;
    padding-left: 0;
    flex-direction: row-reverse;

    .AdditionalCustomerFeatureImage {
      width: vwm(80px);
      height: vwm(80px);
      border: 2px solid;
      border-top: 0;
      // padding-left: vwm(10px);
      border-right: 0;
      border-image: linear-gradient(
        to bottom,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;
    }

    border: 2px solid;
    padding-bottom: vwm(9px);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-image: linear-gradient(to left, #000000, #5cc8a0, #5cc8a0, #000000);
    border-image-slice: 1;

    .AdditionalFeaturesText {
      margin-left: vwm(14px);
    }
  }

  .Ecosystem {
    display: none;
  }

  .AdditionalFeatures2 {
    margin-top: vwm(73px);

    .HeroText {
      text-align: center;
      font-size: vwm(22px);
    }

    .HeroTextInfo {
      font-size: vwm(15px);
      text-align: center !important;
      width: vwm(342px) !important;
    }
  }

  .Applications {
    flex-direction: column;
    row-gap: vwm(16px);
    // margin-left: vwm(17px);
    // margin-right: vwm(15px);
    margin-top: vwm(30px);

    .ApplicationImage1 {
      width: vwm(56px);
      height: vwm(56px);

      border: 2px solid;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      padding-left: vwm(20px);
      border-image: linear-gradient(
        to bottom,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;
    }
    .ApplicationImage2 {
      width: vwm(80px);
      height: vwm(80px);

      padding-right: vwm(20px);
      border: 2px solid;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      border-image: linear-gradient(
        to bottom,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;
    }
    .ApplicationImage3 {
      width: vwm(92px);
      height: vwm(92px);

      border: 2px solid;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      border-image: linear-gradient(
        to bottom,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;
    }

    .Application {
      flex-direction: row-reverse;
      width: 100% !important;
      padding: unset;
      margin: unset;
      padding-bottom: vwm(12px);

      border: 2px solid;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-image: linear-gradient(
        to left,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;
    }

    .ApplicationText {
      display: flex;
      flex-direction: column;
      padding-right: vwm(13px);
      width: 100% !important;
      align-items: start;

      .ApplicationTextHeader {
        font-size: vwm(18px);
      }

      .ApplicationTextInfo {
        font-size: vwm(15px);
        width: 100% !important;
        text-align: left;
        padding-left: unset !important;
        max-width: none;
      }
    }

    .Application:nth-child(2) {
      border: none;
      flex-direction: row;

      padding-bottom: vwm(12px);

      border: 2px solid;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-image: linear-gradient(
        to left,
        #000000,
        #5cc8a0,
        #5cc8a0,
        #000000
      );
      border-image-slice: 1;

      .ApplicationText {
        padding-right: unset;
        padding-left: vwm(13px);
      }
    }
  }
}
